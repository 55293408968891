<template>
  <!-- 加载屏蔽层 -->
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <!-- 首页版心 -->
    <div id="task-info-layout">
      <div class="index-top">
        <!-- 导航条 -->
        <div class="i-page-header" style="padding: 25px 11px 0 11px;">
          <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 10px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ $store.state.cashNum }}
              </span>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
          <div class="main-1">
              <div @click="$router.push('/earn')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 18px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
          </div>
        </div>


        <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;margin-bottom:50px">
            <div style="float: left;display: inline-block;vertical-align: middle;margin-right: 10px; margin-bottom: 6px">
                  <img :src="taskInfo.appIcon" style="width: 120px;border-radius: 20px;" />
            </div>
            <div style="color: rgba(225, 225, 225, 1); font-size: 20px">{{ taskInfo.appName }}</div>
            <div style=" font-weight: 700;letter-spacing: 1.03px;clear: both; color: rgba(255, 246, 0, 1); font-size: 23px; vertical-align: middle; margin-top: 15px; float: left;">
                {{taskInfo.rewardCurCash}}/{{taskInfo.rewardTotalCash}}
                <img src="@/assets/common/cashIcon.png" style="position: relative; top: 0px; width: 25px; vertical-align: middle; left: 0px;" />
            </div>
            <div style="margin-bottom: 30px;font-weight: 600;letter-spacing: 0.33px;color: rgba(255, 255, 255, 1); font-size: 23px;margin-top: 2px;">
                <span style="font-weight: 545; position: relative; top: 1px; left: -3px; font-size: 23px">≈</span>
                <span>{{ ((taskInfo.rewardTotalCash/10000)*0.8).toFixed(2) }} USD</span>
            </div>
            <div @click="goUrl(taskInfo.downloadLink)" style="font-weight: 700;letter-spacing: 1.03px;display: flex; justify-content: center; align-items: center;background: rgba(81, 215, 120, 1); border-radius: 10px; opacity: 1; width: 270px; height: 50px">
              Go
            </div>
        </div>

        <div class="main-2">
          <div class="main-2-0">
            <div class="main-2-1">
              <span>{{$t('all.i_initialTask')}}</span>
              <img src="@/assets/images/index/numIcon.png" style="width: 16px;vertical-align: middle; margin-left: 10px" />
              <span style="font-size: 15px;color: rgba(107, 108, 128, 1);margin-left: 2px;vertical-align: middle;"> {{ taskInfo.clickNum }} {{$t('all.i_joined')}}</span>
            </div>
          </div>
          <div style="width: 95%;display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; margin-left: 0px;">
            <div style="display: flex; align-items: center; margin-right: auto;">
              <div style="background: rgba(66, 133, 244, 1); color: rgba(255, 255, 255, 1); border-radius: 15.86px; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center; margin-right: 5px;">
                1
              </div>
              <div style="color: rgba(255, 255, 255, 1); font-weight: 400; font-size: 15px; margin-right: 5px;  text-align: left;">
                {{$t('all.i_loginAccount1')}} <img src="@/assets/images/index/googleIcon.png" style="width: 23px; vertical-align: middle;" /> {{$t('all.i_loginAccount3')}}
              </div>
            </div>
            <div v-if="gameInfo.loginStatus != true" @click="goApp(taskInfo.appSchemeLogin)" style="display: flex; justify-content: center; align-items: center; background: rgba(81, 215, 120, 1); width: 70px; height: 39px; font-weight: 400; border-radius: 18px;font-size: 15px;">
                Go
            </div>
            <div v-else style="display: flex; justify-content: center; width: 70px;">
                <img src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 0px;vertical-align: middle;" />
            </div>
          </div>

            <div style="width: 95%; display: flex; justify-content: space-between; align-items: center; margin-left: 0px;">
              <div style="display: flex; align-items: center; margin-right: auto;">
                <div style="background: rgba(66, 133, 244, 1); color: rgba(255,255,255,1);border-radius: 15.86px;opacity: 1; width: 25px; height: 25px; display: flex; justify-content: center; align-items: center;margin-right: 5px">
                  2
                </div>
                <div style="color: rgba(255, 255, 255, 1);font-weight: 400; font-size: 15px; margin-right: 5px; text-align: left;" v-html="luckyDraw"></div>
              </div>
              <div v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" @click="goApp(taskInfo.appSchemeTurntable)" style="display: flex; justify-content: center; align-items: center;background: rgba(81, 215, 120, 1); width: 70px; height: 39px; font-weight: 400;border-radius: 18px;font-size: 15px;">
                Go
              </div>
              <div v-else style="display: flex; justify-content: center; width: 70px;">
                <img src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 0px;vertical-align: middle;" />
            </div>
            </div>
            <div v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" class="main-2-2">
                    {{ gameInfo.turntableNum }}/{{ initTask.conditionNum }}
            </div>
            <div v-else class="main-2-2">
                    {{ initTask.conditionNum }}/{{ initTask.conditionNum }}
            </div>
            <el-button v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" style="margin-left: 0px; display: flex; justify-content: center; align-items: center;width: 265px;border-radius: 10px;background-color: rgba(47, 48, 69, 1);color: black;margin-top: 26px;height: 55px;">
              <span style="font-size: 18px;color: black;text-decoration: none;vertical-align: middle;color: rgba(145, 148, 194, 1);font-weight: 600;">
                 +{{initTask.rewardTotalCash }}
              </span>
              <img src="@/assets/common/cashIcon.png" style="width: 19px;vertical-align: middle;" />
              <br />
              <span style="color: rgba(91, 92, 129, 1);">({{$t('all.i_undone')}})</span>
            </el-button>
            <el-button @click="getTaskReward(initTask.id)" v-if="initTask.ok == false && gameInfo.turntableNum >= initTask.conditionNum" style="margin-left: 0px;display: flex; justify-content: center; align-items: center;width: 265px;border-radius: 10px;background-color: rgba(247, 198, 69, 1);color: black;margin-top: 26px;height: 55px;">
              <span style="font-size: 24px;color: black;text-decoration: none;vertical-align: middle;color: rgba(87, 36, 13, 1);font-weight: 600;">
                +{{ initTask.rewardTotalCash }}
              </span>
              <img src="@/assets/common/cashIcon.png" style="width: 22px;vertical-align: middle;" />
              <br />
              <span>({{$t('all.i_claimRewards')}})</span>
            </el-button>
            <el-button v-if="initTask.ok == true" style="margin-left: 0px; display: flex; justify-content: center; align-items: center;width: 265px;border-radius: 10px;background-color: rgba(213, 242, 232, 1);color: black;margin-top: 5%;height: 55px;">
              <span>({{$t('all.i_completed')}})</span>
            </el-button>
            
        </div>






        

        <!-- <div style="margin-left: 4%;margin-right: 4%;margin-bottom: 6%;">
          <div style="overflow: hidden; margin-top: 20px">
            <div style="float: left;font-size: 28px;">
              <span style="color: rgba(255, 255, 255, 1);">{{$t('all.i_initialTask')}}</span>
              <img src="@/assets/common/personRenmenIcon.png" style="width: 16px;vertical-align: middle; margin-left: 10px" />
              <span style="font-size: 15px;color: rgba(159, 199, 185, 1);margin-left: 2px;vertical-align: middle;"> {{ taskInfo.clickNum }} {{$t('all.i_joined')}}</span>
            </div>
            <div v-if="taskInfo.testStatus" style="float: right;">
              <img src="@/assets/common/testIcon.png" style="width: 22px;vertical-align: middle;margin-top: 4px;" />
            </div>
          </div>

          <div style="background-color: rgba(47, 48, 64, 1);padding: 4% 4%;border-radius: 15px;margin-top: 2%;">
            <div style="overflow: hidden;text-align: left;">
              <div style="color: rgba(205, 206, 225, 1); font-size: 18px">①{{$t('all.i_download')}}“{{ taskInfo.appName }}”</div>
              <div style="margin: 4% 0 4% 0;">
                <div style="float: left;width: 44px;height: 44px;display: inline-block;vertical-align: middle;margin-right: 10px;">
                  <img :src="taskInfo.appIcon" style="width: 44px;height: 44px;border-radius: 10px;" />
                </div>
                <el-button @click="goUrl(taskInfo.downloadLink)" style="border-radius: 30px;background-color: rgba(58, 97, 80, 1);color: white;vertical-align: middle;margin: 2px auto;margin-right: 6px;">{{$t('all.i_downloadNo')}}</el-button>
                <img v-if="taskInfo.platformIcon == 1 || taskInfo.platformIcon == 0" src="@/assets/common/iosIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />
                <img v-if="taskInfo.platformIcon == 2 || taskInfo.platformIcon == 0" src="@/assets/common/androidIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />
              </div>
              <div v-if="initTask != null">
                <div>
                  <span  style="color: rgba(205, 206, 225, 1); font-size: 18px">②{{$t('all.i_loginAccount1')}}</span>
                  <br/>
                  <span style="font-weight: bold;">
                    “<img src="@/assets/common/googleIcon.png" style="width: 20px;height: 20px;vertical-align: middle;margin: auto 5px;" />{{$t('all.i_loginAccount2')}}”
                  </span>
                  {{$t('all.i_loginAccount3')}}
                </div>
                <div style="margin: 4% 0 4% 0;">
                  <span style = "color: rgba(99, 108, 122, 1)">{{$t('all.i_state')}}：</span>
                  <span v-if="gameInfo.loginStatus == true" style="color: rgba(99, 108, 122, 1);">{{$t('all.i_completed')}}</span>
                  <span v-else style="color: rgba(255, 54, 0, 1);">{{$t('all.i_undone')}}</span>
                  <img v-if="gameInfo.loginStatus == true" src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 10px;vertical-align: middle;" />
                  <el-button v-else @click="goApp(taskInfo.appSchemeLogin)" style="border-radius: 30px;background: rgba(58, 97, 80, 1);color: rgba(119, 211, 129, 1);margin-left: 29px;">Go</el-button>
                </div>
                <div v-html="initTask.title" style="color: rgba(205, 206, 225, 1); font-size: 18px"></div>
                <div style="margin: 4% 0 4% 0; color: rgba(99, 108, 122, 1)">
                  <span v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" style="font-size: 24px;vertical-align: middle;">
                    {{ gameInfo.turntableNum }}/{{ initTask.conditionNum }}
                  </span>
                  <span v-else style="font-size: 24px;vertical-align: middle;">
                    {{ initTask.conditionNum }}/{{ initTask.conditionNum }}
                  </span>
                  <img v-if="initTask.ok == true || gameInfo.turntableNum >= initTask.conditionNum" src="@/assets/common/yesIcon.png" style="width: 30px;margin-left: 10px;vertical-align: middle;" />
                  <el-button v-else @click="goApp(taskInfo.appSchemeTurntable)" style="border-radius: 30px;background: rgba(58, 97, 80, 1);color: rgba(119, 211, 129, 1);margin-left: 29px;">Go</el-button>
                </div>
                <el-button v-if="initTask.ok == false && gameInfo.turntableNum < initTask.conditionNum" style="width: 100%;border-radius: 15px;background-color: rgba(119, 208, 160, 1);color: black;margin-top: 5%;height: 68px;">
                  <span style="font-size: 24px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(0, 0, 0, 1);font-weight: 600;">
                    +{{initTask.rewardTotalCash }}
                  </span>
                  <img src="@/assets/common/cashIcon.png" style="width: 22px;vertical-align: middle;" />
                  <br />
                  <span>({{$t('all.i_undone')}})</span>
                </el-button>
                <el-button @click="getTaskReward(initTask.id)" v-if="initTask.ok == false && gameInfo.turntableNum >= initTask.conditionNum" style="width: 100%;border-radius: 15px;background-color: rgba(247, 198, 69, 1);color: black;margin-top: 5%;height: 68px;">
                  <span style="font-size: 24px;color: black;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(87, 36, 13, 1);font-weight: 600;">
                    +{{ initTask.rewardTotalCash }}
                  </span>
                  <img src="@/assets/common/cashIcon.png" style="width: 22px;vertical-align: middle;" />
                  <br />
                  <span>({{$t('all.i_claimRewards')}})</span>
                </el-button>
                <el-button v-if="initTask.ok == true" style="width: 100%;border-radius: 15px;background-color: rgba(213, 242, 232, 1);color: black;margin-top: 5%;height: 68px;">
                  <span>({{$t('all.i_completed')}})</span>
                </el-button>
              </div>
            </div>
          </div>
        </div> -->

        
          <div style="margin-top: 20px; ">
            <div v-for="(task, index) in expTaskList" :key="task.id">
              <div class="main-3">
                <div style="flex: 1; margin-right: 20px; text-align: left; margin-left: 10px;">
                  <div style="color: rgba(203, 204, 225, 1); font-weight: 400; margin-bottom: 5px; font-size: 15px; margin-top: -11px">
                    {{$t('all.i_scoreTask')}}
                  </div>
                  <div style="color: rgba(214, 215, 233, 1); font-size: 16px; margin-bottom: 25px;">
                      {{ task.title }}
                  </div>
                  <el-progress :show-text="false" :stroke-width="10" :percentage="((gameInfo.expNum / task.conditionNum) * 100) >= 100 ? 100 : ((gameInfo.expNum / task.conditionNum) * 100)" :color="customColors1" style="width: 90%; margin-top: 12px;" class="custom-progress"></el-progress>
                </div>
                <div style="text-align: right; margin-right: 12px;">
                  <div>
                    <span style="color: rgba(247, 198, 69, 1); font-size: 20px; vertical-align: middle; font-weight: bold;">
                      {{task.rewardCash}}
                    </span>
                    <img src="@/assets/common/cashIcon.png" style="width: 20px;vertical-align: middle;" />
                  </div>
                  <el-button v-if="gameInfo.expNum < task.conditionNum" @click="goApp(taskInfo.appScheme)" round style="width: 80px; background-color: rgba(47, 48, 69, 1); color: rgba(145, 148, 194, 1);; margin-top: 19px; height: 35px;">{{$t('all.i_claim')}}</el-button>
                  <el-button v-else @click="getTaskReward(task.id)" round style="width: 80px; background-color: rgba(81, 215, 120, 1); color: black; margin-top: 23px;">{{$t('all.i_claim')}}</el-button>
                </div>
              </div>
              <div v-if="(index + 1) != expTaskList.length" style="height: 30px;"></div>
            </div>
            <!-- <div v-if="expTaskList.length <= 0">
              <img src="@/assets/images/invite/yesIcon.png" style="width: 20px;vertical-align: middle;margin-bottom: 10px;" />
              <div style="color: rgba(94, 172, 140, 1);">{{$t('all.i_allTasksCompleted')}}</div>
            </div> -->
          </div>

      
          <div style="margin-top: 20px;">
            <div v-for="(task, index) in advanceTaskList" :key="task.id" >
              <div class="main-4">
                  <div style="flex: 1; margin-right: 20px; text-align: left; margin-left: 10px;">
                      <div style="color: rgba(203, 204, 225, 1); font-weight: 400; margin-bottom: 5px; font-size: 15px; margin-top: -11px">
                          {{$t('all.i_coinsTask')}}
                      </div>
                      <div style="; color: rgba(214, 215, 233, 1); font-size: 16px; margin-bottom: 25px;">
                          {{ task.title }}
                      </div>                          
                      <el-progress :show-text="false" :stroke-width="10" :percentage="((gameInfo.goldNum / task.conditionNum) * 100) >= 100 ? 100 : ((gameInfo.goldNum / task.conditionNum) * 100)" :color="customColors" style="width: 90%; margin-top: 12px;" class="custom-progress"></el-progress>
                  </div>
                  <div style="text-align: right; margin-right: 12px;">
                      <div>
                          <span style="color: rgba(247, 198, 69, 1); font-size: 20px; vertical-align: middle; font-weight: bold;">
                              {{task.rewardCash}}
                          </span>
                          <img src="@/assets/common/cashIcon.png" style="width: 20px; vertical-align: middle;" />
                      </div>
                      <el-button v-if="gameInfo.goldNum < task.conditionNum" @click="goApp(taskInfo.appScheme)" round style="width: 80px; background-color: rgba(47, 48, 69, 1); color: rgba(145, 148, 194, 1); margin-top: 19px; height: 35px;">{{$t('all.i_claim')}}</el-button>
                      <el-button v-else @click="getTaskReward(task.id)" round style="width: 80px; background-color: rgba(81, 215, 120, 1); color: black; margin-top: 23px;">{{$t('all.i_claim')}}</el-button>
                  </div>
              </div>
              <div v-if="(index + 1) != advanceTaskList.length" style="height: 30px;"></div>
            </div>
            <!-- <div v-if="advanceTaskList.length <= 0" >
              <img src="@/assets/images/invite/yesIcon.png" style="width: 20px;vertical-align: middle;margin-bottom: 10px;" />
              <div style="color: rgba(94, 172, 140, 1);">{{$t('all.i_allTasksCompleted1')}}</div>
            </div> -->
          </div>
          
        </div>
        
        <div style="height: 15vh"></div>

      

      <el-dialog :title="$t('all.i_earnRewards')" :visible.sync="rewardDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;background-color: rgba(244, 245, 247, 1);padding: 6%;border-radius: 10px;">
          <span style="font-size: 24px;color: #000;vertical-align: middle;">{{rewardNum}}</span>
          <img src="@/assets/common/cash1Icon.png" style="width: 18px;vertical-align: middle;margin-left: 4px;" />
        </div>
        <div style="text-align: center;">
          <el-button type="primary" @click="rewardDialog = false" style="width: 80%;background-color: rgba(247, 198, 69, 1);color: black;border: none;border-radius: 15px;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>

      <!-- 错误信息Dialog -->
      <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnPressEscape="false">
        <div style="text-align: center;margin-bottom: 10%;">
          <div style="font-size: 16px;color: #000;">{{ failMessage }}</div>
        </div>
        <div style="text-align: center;">
          <el-button round type="primary" @click="go()" style="width: 60%;">
            {{ $t('convert.i_ok') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
    
  </div>
</template>

<script>


export default {
  
  
  data() {
    return {
      // 加载屏蔽层状态
      isLoading: false,
      userInfo: {
        uid: 0,
        cashNum: 0,
        inviteCode: "",
        appName: "",
        appScheme: "",
        isActivation: false,
        userStatus: false
      },
      failMessage: null,
      checkDialog: false,
      customColors: "rgba(81, 215, 120, 1)",
      customColors1: "rgba(81, 215, 120, 1)",
      taskInfo: {},
      initTask: {},
      advanceTaskList: [],
      expTaskList: [],
      gameInfo: {},
      rewardDialog: false,
      rewardNum: "",
      luckyDraw: ""
    };
  },
  methods: {
    getTaskInfo() {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/taskInfo",
        method: "get",
        params: {
          tid: this.$route.query.tid,
          inviteCode: this.$route.query.referrer
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.userInfo = result.data.data.userInfo;
          this.$store.state.cashNum = result.data.data.userInfo.num;
          this.taskInfo = result.data.data.taskInfo;
          this.gameInfo = result.data.data.gameInfo;
          this.initTask = result.data.data.taskInfo.initTask;
          this.advanceTaskList = result.data.data.taskInfo.advanceTaskList;
          this.expTaskList = result.data.data.taskInfo.expTaskList;

          this.luckyDraw = this.initTask.title.slice(1);
          
        } else if (result.data.code == 0) {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
          this.$router.push("/index");
        }
      });
    },
    getTaskReward(subTid) {
      this.isLoading = true;
      this.$axios({
        url: "/webCash/taskReward",
        method: "post",
        params: {
          tid: this.$route.query.tid,
          subTid: subTid
        }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.code == 1) {
          this.getTaskInfo();
          this.rewardNum = result.data.data.rewardNum;
          this.rewardDialog = true;
        } else {
          this.$message({
            showClose: true,
            message: result.data.message,
            type: "error",
            center: true
          });
        }
      });
    },
    go() {
      if (this.userInfo.userStatus == false) {
        window.location.href = this.userInfo.appScheme + "login";
      }
      if (this.userInfo.isActivation == false) {
        window.location.href = this.userInfo.appScheme;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goApp(url) {
      window.location.href = url;
    }
  },
  created() {
    this.getTaskInfo();
    console.log('Previous Path:', this.$store.state);
  }
};
</script>

<style>
#task-info-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}

#task-info-layout .index-top {
  padding-bottom: 2%;
}

#task-info-layout .i-page-header {
  overflow: hidden;
  
  background-color: rgba(28, 29, 47, 1)
}

#task-info-layout .el-page-header__left::after {
  display: none;
}

#task-info-layout .i-gift-code-btn,
#task-info-layout .bottom-menu-bar .el-button {
  border: none;
  background: none;
}

#task-info-layout .el-drawer {
  border-radius: 20px 20px 0 0 !important;
}

.custom-progress .el-progress-bar__outer {
    background-color: rgba(24, 25, 41, 1) !important;  /* 设置底部颜色 */
}


 @media screen and (min-width: 1280px){
  #task-info-layout .main-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: 35px; 
    margin-left: -1130px;
    width: 1200px;
  }
  
  #task-info-layout .main-2{
    margin: auto; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background: rgba(34, 35, 56, 1); 
    width: 1200px; 
    height: 267px;
    border-radius: 12px;
  }

  #task-info-layout .main-2-0{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    width: 1155px;
  }

  #task-info-layout .main-2-1{
    color: rgba(203, 204, 225, 1); 
    font-weight: 400;
    /* margin-left: -89%; */
    
    margin-bottom: 12px;
    margin-top: -15px
  }

  #task-info-layout .main-2-2{
    color:rgba(255,255,255,1);
    font-weight: 400;
    font-size: 18px;
    vertical-align: middle;
    margin-left: -86%
  }

  #task-info-layout .main-3 {
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 1200px; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    /* padding: 0 20px; */
  }

  #task-info-layout .main-4{
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 1200px; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    /* padding: 0 20px; */
  }
 }

 @media screen and (max-width: 1500px){
    
    #task-info-layout .main-1{
      display: flex; 
      justify-content: left; 
      align-items: center; 
      margin-top: 20px; 
      margin-left: 0px;
      margin-bottom: 20px;
      width: 93%;
    }
    #task-info-layout .main-2{
    margin: auto; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background: rgba(34, 35, 56, 1); 
    width: 93%; 
    height: 267px;
    border-radius: 12px;
  }
  #task-info-layout .main-2-1{
    color: rgba(203, 204, 225, 1); 
    font-weight: 400;
    /* margin-left: -76%; */
    margin-bottom: 12px;
    margin-top: -15px
  }
  #task-info-layout .main-2-0{
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    width: 95%;
  }
  #task-info-layout .main-2-2{
    color:rgba(255,255,255,1);
    font-weight: 400;
    font-size: 18px;
    vertical-align: middle;
    margin-left: -67%
  }
  #task-info-layout .main-3 {
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 93%; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
  }

  #task-info-layout .main-4{
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 93%; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
  }
 }

 @media screen and (min-width: 768px) and (max-width: 1500px) {
  #task-info-layout .main-1{
    display: flex; 
    justify-content: left; 
    align-items: center; 
    margin-top: 35px; 
    margin-left: 0px;
    margin-bottom: 0px;
    width: 85%
  }
  #task-info-layout .main-2{
    margin: auto; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background: rgba(34, 35, 56, 1); 
    width: 85%; 
    height: 267px;
    border-radius: 12px;
  }
  /* #task-info-layout .main-2-1{
    color: rgba(203, 204, 225, 1); 
    font-weight: 400;
    margin-left: -85%;
    margin-bottom: 12px;
    margin-top: -15px
  } */
  #task-info-layout .main-2-2{
    color:rgba(255,255,255,1);
    font-weight: 400;
    font-size: 18px;
    vertical-align: middle;
    margin-left: -82%
  }

  #task-info-layout .main-3 {
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 85%; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
  }

  #task-info-layout .main-4{
    background-color: rgba(34, 35, 56, 1); 
    height: 120px; 
    width: 85%; 
    margin: auto; 
    margin-bottom: -20px; 
    border-radius: 15px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
  }
}
</style>
