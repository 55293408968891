<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="allTask-layout">
        <!-- 导航条 -->
        <div class="i-page-header" style="padding: 25px 11px 0 11px;">
          <img src="@/assets/common/bigbigcash2Icon.png"  class="top-1" />
          <span class="top-button">
              <div class="top-button1" style="display: flex; align-items: center; flex-wrap: nowrap;">
                  <span @click="$router.push('/index')" class="main-0">
                      <img src="@/assets/images/index/indexIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_home')}}</div>
                  </span>
                  
                  <span @click="$router.push('/')" class="main-0">
                      <img src="@/assets/images/index/exchangeIcon.png" style="width: 18px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_redeem')}}</div>
                  </span>
                  
                  <span @click="$router.push('/earn')" class="main-0" style="background-color: rgba(80, 270, 119, 1);display: flex; flex-direction: column; align-items: center; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 90px; height: 49px;">
                      <img src="@/assets/images/index/earnIcon1.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(0, 0, 0, 1); font-size: 12px; margin-top: 2px;width: 45px">{{$t('index.i_earnMoney')}}</div>
                  </span>
                  
                  <span @click="$router.push('/inviteFriend')" class="main-0">
                      <img src="@/assets/images/index/inviteIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_invite')}}</div>
                  </span>
                  
                  <span @click="$router.push('/account')" style="display: flex; flex-direction: column; align-items: center;">
                      <img src="@/assets/images/index/accountIcon.png" style="width: 20px; vertical-align: middle;" />
                      <div style="color: rgba(255, 255, 255, 1); font-size: 12px; margin-top: 5px;width: 45px">{{$t('index.i_account')}}</div>
                  </span>
              </div>
          </span>
          <div class="top-2">
            <div style="background-color: rgba(19, 21, 34, 1);padding: 3px 7px 4px 7px;border-radius: 20px;display: inline-block;vertical-align: middle;margin-right: 6px;">
              <img src="@/assets/common/cashIcon.png" style="width: 18px;vertical-align: middle;" />
              <span style="font-size: 15px;text-decoration: none;vertical-align: middle;margin-right: 4px;color: rgba(246, 243, 254, 1);font-weight: 600;">
                {{ userInfo.num }}
              </span>
            </div>
            <!-- <div @click="$router.push('/personal')" style="width: 36px;height: 36px;display: inline-block;vertical-align: middle;">
              <img src="@/assets/images/index/avatar.png" style="width: 36px;height: 36px;border-radius: 30px;" />
              <img :src="userInfo.avatar" style="width: 36px;height: 36px;border-radius: 30px;margin-left: -36px;" />
            </div> -->
          </div>
        </div>

        <div style="margin-top: 10px">
          <swiper :options="swiperOption1"  class="my-swiper">
            <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <div style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Gabriel...</div>
                  </div>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Alexan...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">40PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payPalIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">PayPal</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlott...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3$</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/linePayIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">LinePay</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Mia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">10TWD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Victor...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">3USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/trueMoneyIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-left: -5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">True Money</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Sophia</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">50THB</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          " class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/gcashIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Gcash</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Charlo...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">150PHP</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/payeerIcon.png" class="main-top-1"  />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Payeer</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Benja...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">5USD</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                <div class="main-top">
                  <img src="@/assets/images/index/pixIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Pix</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">Jonatha...</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 11px;letter-spacing: 1.5px">2.5BRL</span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide style="flex: 0 0 auto; 
                          width:100px;" class="swiper">
                  <div class="main-top">
                  <img src="@/assets/images/index/danaIcon.png" class="main-top-1" />
                  <span style="text-align: left; margin-right: 5px;">
                    <div style="color: rgba(199, 200, 218, 1); font-size: 10px;margin-bottom: 2px;">Dana</div>
                    <div style="color: rgba(124, 118, 148, 1); font-size: 10px;">James</div>
                  </span>
                  <div class="main-top-2">
                      <span style="color: rgba(255,255,255,1); font-size: 9px;letter-spacing: 1.5px">8000IDR</span>
                  </div>
                </div>
              </swiper-slide>
                      
          </swiper>
          <div  style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <div class="main-1">
              <div @click="$router.push('/earn')" style="display: flex; justify-content: center; align-items: center; background: rgba(40, 37, 56, 1); width: 80px; height: 33px; ">
                  <img src="@/assets/images/index/arrowIcon2.png" style="max-width: 15px; height: auto; margin-right: 6px" />
                  <div style="color: rgba(164, 159, 191, 1); font-weight: 700; letter-spacing: 1.46px; font-size: 15px">{{$t('all.i_back')}}</div>
              </div>
            </div>
        </div>
        </div>

        <div class="main-2">
            <div :class="platform == 0 ? 'platformAct' : ''" @click="platform = 0; filterTaskList(0)" style="display: flex; justify-content: center; align-items: center;background-color: rgba(37, 40, 57, 1); color: rgba(255,255,255,1); font-size: 15px;font-weight: 700;letter-spacing: 0.86px;width: 70px; height: 30px; margin-right: 2px">
                All
            </div>
            <div :class="platform == 1 ? 'platformAct' : ''" @click="platform = 1; filterTaskList(1)" style="display: flex; justify-content: center; align-items: center;background-color: rgba(37, 40, 57, 1); width: 70px; height: 30px; margin-right: 2px">
                <img src="@/assets/images/index/iosIcon.png" style="max-width: 15px; height: auto; " />
            </div>
            <div :class="platform == 2 ? 'platformAct' : ''" @click="platform = 2; filterTaskList(2)" style="display: flex; justify-content: center; align-items: center;background-color: rgba(37, 40, 57, 1); width: 70px; height: 30px">
                <img src="@/assets/images/index/androidIcon.png" style="max-width: 15px; height: auto; " />
            </div>
        </div>


        <div v-if="taskType == 1">
          <div style="margin-top: 30px" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div  style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
              <div class="main-1-1" >
                    <div class="main-1-1-1">{{$t('all.i_latest')}}</div>
              </div>
            </div>
            <div class="main-1-2" style="margin-top: 90px">
                <div style="width: 110px; height: 30px; margin-bottom: 180px; margin-right: 7px; display: flex; justify-content: center; align-items: center;" v-for="(task, index) in taskList" :key="task.id">
                  <div  style="display: flex; align-items: center; flex-direction: column;background: linear-gradient(180deg, rgba(47, 48, 83, 1) 0%, rgba(47, 48, 83, 1) 1%, rgba(34, 35, 55, 1) 99.63%, rgba(34, 35, 55, 1) 100.63%); width: 120px; height: 198px; border-radius: 15px">
                            <div style="position: relative; display: flex; align-items: flex-start; justify-content: flex-end; margin-bottom: 8px; margin-top: 8px;">
                                <div v-if="index == 0 || isFinished">
                                    <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="border-radius: 12px; width: 90px; height: 85px;" />
                                </div>
                                <div v-else>
                                   <img @click="taskInfo(task.id)" src="@/assets/images/index/taskIcon.png" style="border-radius: 12px; width: 90px; height: 85px;" />
                                   <img @click="taskInfo(task.id)" src="@/assets/images/index/lockIcon.png" style="width: 25px; position: absolute; left: 32px; top: 31px;"/>
                                </div>
                                <div style="display: flex;  align-items: flex-end; position: absolute; right: 5px; top: 5px;">
                                    <img v-if="task.platformIcon == 2 || task.platformIcon == 0" src="@/assets/images/index/androidIcon1.png" style="width: 20px; height: 20px;" />
                                    <img v-if="task.platformIcon == 1 || task.platformIcon == 0" src="@/assets/images/index/iosIcon1.png" style="width: 20px; height: 20px;" />
                                </div>
                            </div>
                            <div style="font-weight: 400;width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: rgba(255, 255, 255, 1);font-size: 14px;margin-bottom: 0px;">
                              {{task.appName}}
                            </div>
                            <div @click="taskInfo(task.id)" style="width: 90px;">
                                <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" :backround-color="customColor" class="custom-progress" style="width: 100%; float: left; vertical-align: middle; margin-top: 11px;"></el-progress>
                                <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" class="custom-progress" style="width: 100%; float: left; vertical-align: middle; margin-top: 11px; "></el-progress>
                                <div style="font-weight: 700;letter-spacing: 1.03px;clear: both; color: rgba(244, 198, 53, 1); font-size: 13px; vertical-align: middle; margin-top: 11px; float: left;">
                                    +{{ task.rewardTotalCash }}
                                    <img src="@/assets/common/cashIcon.png" style="position: relative; top: -5px; width: 18px; vertical-align: middle; left: 2px;" />
                                </div>
                            </div>
                            <div style="font-weight: 600;letter-spacing: 0.33px;color: rgba(255, 255, 255, 1); font-size: 13px;margin-top: 2px;">
                              <span style="font-weight: 545; position: relative; top: 1px; left: -3px; font-size: 15px">≈</span>
                              <span>{{ ((task.rewardTotalCash/10000)*0.8).toFixed(2) }} USD</span>
                            </div>
                        </div>
                </div>
              
            </div>
          </div>
        </div>

        <div v-if="taskType == 2">
          <div style="margin-top: 30px" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div  style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
              <div class="main-1-1" >
                    <div class="main-1-1-1">{{$t('all.i_hottest')}}</div>
              </div>
            </div>
            <div class="main-1-2" style="margin-top: 90px;">
                <div style="width: 110px; height: 30px; margin-bottom: 180px; margin-right: 7px; display: flex; justify-content: center; align-items: center;" v-for="(task) in taskListByClickNum" :key="task.id">
                  <div  style="display: flex; align-items: center; flex-direction: column; background: linear-gradient(0deg, rgba(54, 83, 47, 1) 0%, rgba(54, 83, 47, 1) 1%, rgba(34, 35, 55, 1) 99.63%, rgba(34, 35, 55, 1) 100.63%); width: 120px; height: 198px; border-radius: 15px">
                            <div style="position: relative; display: flex; align-items: flex-start; justify-content: flex-end; margin-bottom: 8px; margin-top: 9px;">
                                <div v-if="index == 0 || isFinished">
                                    <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="border-radius: 12px; width: 90px; height: 85px;" />
                                </div>
                                <div v-else>
                                   <img @click="taskInfo(task.id)" src="@/assets/images/index/taskIcon.png" style="border-radius: 12px; width: 90px; height: 85px;" />
                                   <img @click="taskInfo(task.id)" src="@/assets/images/index/lockIcon.png" style="width: 25px; position: absolute; left: 32px; top: 31px;"/>
                                </div>

                                <div style="display: flex;  align-items: flex-end; position: absolute; right: 5px; top: 5px;">
                                    <img v-if="task.platformIcon == 2 || task.platformIcon == 0" src="@/assets/images/index/androidIcon1.png" style="width: 20px; height: 20px;" />
                                    <img v-if="task.platformIcon == 1 || task.platformIcon == 0" src="@/assets/images/index/iosIcon1.png" style="width: 20px; height: 20px;" />
                                </div>
                            </div>
                            <div style="font-weight: 400;width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: rgba(255, 255, 255, 1);font-size: 14px;margin-bottom: 0px;">
                              {{task.appName}}
                            </div>
                            <div @click="taskInfo(task.id)" style="width: 90px;">
                                <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" :backround-color="customColor" class="custom-progress1" style="width: 100%; float: left; vertical-align: middle; margin-top: 12px;"></el-progress>
                                <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" class="custom-progress1" style="width: 100%; float: left; vertical-align: middle; margin-top: 12px; "></el-progress>
                                <div style="font-weight: 700;letter-spacing: 1.03px;clear: both; color: rgba(244, 198, 53, 1); font-size: 13px; vertical-align: middle; margin-top: 11px; float: left;">
                                    +{{ task.rewardTotalCash }}
                                    <img src="@/assets/common/cashIcon.png" style="position: relative; top: -5px; width: 18px; vertical-align: middle; left: 2px;" />
                                </div>
                            </div>
                            <div style="font-weight: 600;letter-spacing: 0.33px;color: rgba(255, 255, 255, 1); font-size: 13px;margin-top: 2px;">
                              <span style="font-weight: 545; position: relative; top: 1px; left: -3px; font-size: 15px">≈</span>
                              <span>{{ ((task.rewardTotalCash/10000)*0.8).toFixed(2) }} USD</span>
                            </div>
                      </div>
                </div>
              
            </div>
          </div>
        </div>

       <div style="margin: auto; display: flex; justify-content: center; align-items: center; flex-direction: column; background-color: rgba(29, 30, 48, 1); height: 138px; margin-top: 300px;">
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1.09px; margin-bottom: 12px; font-size: 15px;">
              <span style="">《{{$t('index.i_privacyPolicy')}}》</span>
              <span style="">《{{$t('index.i_termsOfService')}}》</span>
          </div>
          <div style="color: rgba(125, 126, 157, 1);font-weight: 400; letter-spacing: 1px; font-size: 12px">
            © 2020 - 2024 BIgbig Cash 2. All rights reserved.
          </div>
        </div>

        <div style="height: 10vh"></div>
        <!-- <div style="margin-top: 20px" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <div v-for="(task) in taskList" :key="task.id" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;">
                <div style="overflow: hidden; margin: 0; padding: 0; background: linear-gradient(180deg, rgba(68, 62, 110, 1) 0%, rgba(48, 49, 75, 1) 100%); border-radius: 10px; height: 290px;">
                  <div style="float: left; height: 60px;">
                    <img @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" :src="task.appIcon" style="position: relative; width: 135px; height: 135px; top: 15px; left: 5px; margin-right: 10px; border-radius: 12px;" />
                    <div @click="$router.push({ path: '/taskInfo', query: { tid: task.id } })" style="display: inline-block; vertical-align: top; margin-top: 20px">
                      <span v-html="task.title" style="color: rgba(124, 120, 176, 1); font-size: 15px; font-weight: bold; float: left; margin-bottom: 6px; width: 135px; height: 50px; line-height: 21px; text-align: left; overflow: hidden; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"></span>
                      <br />
                      <el-progress v-if="task.curTaskNum == 0" :show-text="false" :stroke-width="10" :percentage="0" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <el-progress v-else :show-text="false" :stroke-width="10" :percentage="(task.curTaskNum / task.totalTaskNum) * 100" :color="customColors" style="width: 100%; float: left; vertical-align: middle; margin-top: 4px;"></el-progress>
                      <div style="clear: both; color: rgba(255, 255, 255, 1); font-size: 19px; vertical-align: middle; margin-top: 20px; float: left;">
                        +{{ task.rewardTotalCash }}
                      </div>
                      <img src="@/assets/common/cashIcon.png" style="position: relative; top: 20px; width: 20px; vertical-align: middle; left: -15px;" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="taskList.length % 2 === 1" style="flex: 1 1 calc(50% - 10px); box-sizing: border-box;"></div>
            </div>
          </div> -->

          <el-dialog :title="$t('index.i_login')" :visible.sync="taskDialog" width="300px" :close-on-click-modal="false">
             <div v-if="taskList[0] != null">
              <div style="margin-top: 16px; margin-bottom: 18px; position: relative;">

                  <img :src="taskList[0].appIcon" style="width: 100px" />
                  <div style="display: flex;  align-items: flex-end; position: absolute; right:90px; top: 5px;">
                      <img v-if="taskList[0].platformIcon == 2 || taskList[0].platformIcon == 0" src="@/assets/images/index/androidIcon1.png" style="width: 20px; height: 20px;" />
                      <img v-if="taskList[0].platformIcon == 1 || taskList[0].platformIcon == 0" src="@/assets/images/index/iosIcon1.png" style="width: 20px; height: 20px;" />
                  </div>
              </div>
              <div style="display: flex; justify-content: center;font-weight: 600;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: rgba(255, 255, 255, 1);font-size: 16px;margin-bottom: 17px;">
                     
                     {{taskList[0].appName}}
              </div>
              <div style="display: flex; justify-content: center;">
                  <div style="font-weight: 700;letter-spacing: 1.03px;clear: both; color: rgba(244, 198, 53, 1); font-size: 25px; vertical-align: middle; float: left;">
                      +{{ taskList[0].rewardTotalCash }}
                      <img src="@/assets/common/cashIcon.png" style="position: relative; top: -3px; width: 25px; vertical-align: middle; left: -3px;" />
                  </div>
              </div>
              <div style="font-weight: 600;letter-spacing: 0.33px;color: rgba(255, 255, 255, 1); font-size: 23px;margin-top: 2px; margin-bottom: 15px;">
                  <span style="font-weight: 545; position: relative; top: 1px; left: -3px; font-size: 2px">≈</span>
                  <span>{{ ((taskList[0].rewardTotalCash/10000)*0.8).toFixed(2) }} USD</span>
              </div>
              <div style="display: flex; justify-content: center; color: rgba(255, 255, 255, 1);font-weight: 700;letter-spacing: 0.66px; font-size: 13px; margin-bottom: 12px; margin-top: 30px;">
                {{$t('index.i_completeTheFirstTask')}}
              </div>
              <div @click="$router.push({ path: '/taskInfo', query: { tid: taskList[0].id } })" style="display: flex; justify-content: center; align-items: center;margin-bottom: 15px">
                    <div style="display: flex; justify-content: center; align-items: center;font-weight: 700;letter-spacing: 1.66px; font-size: 15px;color: rgba(39, 32, 6, 1);background: rgba(81, 215, 120, 1); width:200px; height:42px; border-radius: 10px;">
                        {{$t('index.i_goNow')}}
                    </div>
              </div>
              <div @click="taskDialog = fasle">
                    <img src="@/assets/images/index/returnIcon.png" style="position: relative; top: 38px; width: 39px; vertical-align: middle; left: -3px;" />
              </div>
             </div>
          </el-dialog>
    </div>
  </div>
</template>


<script>
export default {
  
  data() {
    return {
        isLoading: false,
        userInfo: {
            num: 0,
        },
        failMessage: null,
        checkDialog: false,
        taskDialog: false,
        isFinished: false,
        cashLog: [],
        busy: false,
        pageNum: 0,
        isLast: false,
        maxReward: 0,
        taskList: [],
        taskListByClickNum: [],
        taskListTemp: [],
        taskListByClickNumTemp: [],
        platform: 0,
        taskType: 2,
        
        customColors: "rgba(255, 255, 255, 1)",
        // swiperOption1: {
        //     slidesPerView: 'auto',
        //     spaceBetween: -38,
        //     loop: true,
        //     freeMode: true,
        //     grid: {
        //         fill: 'column',
        //         rows: 1,
        //     },
        // },
        swiperOption1: {
         slidesPerView: 'auto',
         spaceBetween: -70,
         loop: true,
         grid: {
            fill: 'column',
            rows: 1,
         },
         speed:9500,
         freeMode:true,

            autoplay: {

              delay:0,
              disableOnInteraction: false,
            },
        loopAdditionalSlides: 1
      },
        swiperOption2: {
            slidesPerView: 'auto', // 根据内容自适应
            spaceBetween: -50, // 设置每个幻灯片之间的空间
            // centeredSlides: true, // 中心幻灯片
            watchOverflow: true,
            
            height : window.innerHeight,
            // loop: true, // 启用循环
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
             
            // 其他选项...
        },
         swiperOption3: {
            slidesPerView: 'auto', // 根据内容自适应
            spaceBetween: -50, // 设置每个幻灯片之间的空间
            // centeredSlides: true, // 中心幻灯片
            watchOverflow: true,
            
            height : window.innerHeight,
            // loop: true, // 启用循环
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            // 其他选项...
        },
        };
    },

    methods: {
      taskInfo(taskId){
        if(this.isFinished){
        this.$router.push({ path: '/taskInfo', query: { tid: taskId } })
        }else{
          this.taskDialog = true;
        }
      },

    filterTaskList(platformIcon){

      this.platform = platformIcon;
      this.isLast = false;
      this.pageNum = 0;
      this.taskList = [];
      this.taskListByClickNum = [];
      this.loadMore();

    },
   
      
    sortedTaskList() {
     this.taskList.sort((a, b) => a.clickNum - b.clickNum);
      console.log(this.taskList);
    
    },
    slidePrev() { 
        document.getElementById('swiper9').swiper.slidePrev()    
      
    },
    slideNext() {
        document.getElementById('swiper9').swiper.slideNext()
    },
    slidePrev1() { 
        document.getElementById('swiper8').swiper.slidePrev()    
      
    },
    slideNext1() {
        document.getElementById('swiper8').swiper.slideNext()
    },
    loadMore: function () {
      if (this.isLast != true) {
        this.isLast = true;       
        this.busy = true;
        this.pageNum++;
        this.isLoading = true;  
        this.$axios({
          url: "/webCash/taskList",
          method: "get",
          params: {
            pageNum: this.pageNum,
            platform: this.platform,
          }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {           
            result.data.data.taskList.forEach((element) => {
              this.taskList.push(element);
            });
            result.data.data.taskListByClickNum.forEach((element) => {
              this.taskListByClickNum.push(element);
            });
            this.isLast = result.data.data.isLast;

            if (result.data.data.userInfo != null) {
                this.userInfo.num = result.data.data.userInfo.num;
            } 
          }
          
        });
        this.busy = false;
        if (this.failMessage != null) {
          this.checkDialog = true;
        }
      }
    },
    getIsFinishedSubTask() {
        this.$axios({
          url: "/webCash/isFinishedSubTask",
          method: "get",
        }).then((result) => {
          if (result.data.code == 1) {
            this.isFinished = result.data.data.isFinished;
            if(!this.isFinished){
              this.taskDialog = true;
            }
          }

        });
    },
    },
    created() {
      this.taskType = this.$route.params.taskType;
      this.getIsFinishedSubTask();
      this.loadMore();
    },
  }
</script>

<style>
#allTask-layout {
  max-width: 130rem;
  margin: auto;
  background-color: rgba(1, 1, 1, 1);
}

#allTask-layout .gl-page-header {
  overflow: hidden;
  padding: 4% 4% 0 4%;
}
#allTask-layout .i-page-header {
  overflow: hidden;
  
  background-color: rgba(28, 29, 47, 1)
}

#allTask-layout .custom-progress .el-progress-bar__outer {
    background-color: rgba(66, 63, 95, 1) !important;  /* 设置底部颜色 */
    height: 5px !important;
}

#allTask-layout .custom-progress1 .el-progress-bar__outer {
    background-color: rgba(76, 106, 78, 1) !important;  /* 设置底部颜色 */
    height: 5px !important;
}

#allTask-layout .el-dialog__header {
  display: none; /* 隐藏头部 */
}
#allTask-layout .el-dialog__body{
  background: linear-gradient(325.12deg, rgba(72, 40, 77, 1) 0%, rgba(72, 40, 77, 1) 1.12%, rgba(39, 31, 54, 1) 46%, rgba(44, 91, 76, 1) 100%) !important;
  border-radius:15px !important;
  height: 335px;
  padding-top: 10px !important;
}


@media screen and (max-width: 1280px) {

  #allTask-layout .main-1{
    display: flex; 
    justify-content: left; 
    align-items: center; 
    margin-top: 35px; 
    margin-left: 0px;
    width: 92%;
  } 
  
  #allTask-layout .main-1-1 {
    display: flex; 
    justify-content: left; 
    align-items: center;  
    /* gap:210px; */
    margin-bottom: 20px; 
    margin-left: 0px;  
    width: 92%;
 }

  #allTask-layout .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 346px; 
    height: 160px; 
    margin-bottom: 20px;
    border-radius: 18px;
  }

 

  /* #allTask-layout .my-swiper {
  
  margin-left:20px;
  } */

  #allTask-layout .main-1-2{
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center;
  margin:auto;
  width: 92%;
 }
 
}

@media screen and (min-width: 1280px) {
 
  #allTask-layout .my-swiper {
  width: 1200px; /*设置Swiper的宽度*/
  overflow: hidden; /* 隐藏溢出的内容 */
  margin: auto; /* 水平居中 */
  }
  #allTask-layout .main-4-1{
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    background-color: rgba(29, 30, 48, 1); 
    width: 1200px; 
    height: 160px; 
    margin-top: 10px;
    border-radius: 18px;
  }

  #allTask-layout .main-1{
  display: flex; 
  justify-content: left; 
  align-items: center; 
  margin-top: 35px; 
  margin-left: 0px;
  width: 1200px;
 }

  #allTask-layout .main-1-1 {
    display: flex; 
    justify-content: left; 
    align-items: center;  
    margin-left: 0px;
    margin-bottom: 20px;   
    width: 1212px;
 }

 #allTask-layout .main-2{
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: -68px; 
  height: 10vh
 }

 #allTask-layout .main-1-1-1 {
    color: rgba(255,255,255,1);
    font-weight: 700;
    letter-spacing: 1.66px;
    font-size: 20px; 
    position: relative; 
    left: 10px;
 }

  #allTask-layout .main-1-2-1{
    display: flex; 
    justify-content: center; 
    align-items: center;
 }

 #allTask-layout .main-1-2{
  display: flex; 
  flex-wrap: wrap; 
  /* justify-content: center;  */
  align-items: center;
  width: 1200px; 
  margin:auto
 }

}

@media screen and (max-width: 1500px){
  #allTask-layout .main-1-2-1{
    display: none
 }
 


 #allTask-layout .main-2{
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: 5px; 
  height: 10vh
 }

 #allTask-layout .main-1-1-1 {
    color: rgba(255,255,255,1);
    font-weight: 700;
    letter-spacing: 1.66px;
    font-size: 20px; 
    
 }
}

#allTask-layout .swiper{
  width:230px !important;
  
}

#allTask-layout .platformAct {
  background-color: rgba(80, 220, 119, 1)!important;
  /* color: rgba(35, 53, 63, 1)!important; */
  /* border-radius: 30px; */
}




</style>